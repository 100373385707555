import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../../../components/layout';
export const _frontmatter = {
  "title": "How it works"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How it works`}</h1>
    <p>{`Blocks is a parser, transformer, and renderer/compiler.`}</p>
    <p>{`It's unique because it reads in valid, production-ready JSX code
and treats the AST as its data structure. Information is `}<em parentName="p">{`queried`}</em>{`
from the AST and then changes to the canvas `}<em parentName="p">{`transform`}</em>{` the AST.`}</p>
    <p>{`It uses Babel and its plugin API for parsing and transforming.
Events that happen in the canvas are emitted and run corresponding
plugins on the source code.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: `}<em parentName="p">{`Blocks is still extremely alpha software so a wide array
of optimizations and other enhancements are missing which includes
maintaining the source as the AST structure rather than a JSX string
and maintaining an internal representation for rendering after the
first inline function evaluation`}</em>{`.`}</p>
    <h2>{`Preparing the source code`}</h2>
    <p>{`Blocks essentially maintains two states of the source code internally.
One is a slight transform from the original source code with a UUID added.
This is used to determine what JSX elements to modify. The second state
of source code is the transformed version which is used to render the
canvas.`}</p>
    <h3>{`Internal representation`}</h3>
    <p>{`The internal representation of the source code looks pretty similar to
what was passed in. The only noticeable change is the addition of a
UUID that's passed as a prop (`}<inlineCode parentName="p">{`___uuid`}</inlineCode>{`).`}</p>
    <h4>{`Input`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Blocks from 'blocks-ui/components'

export default () => (
  <Blocks.Root>
    <h1>Hello, world!</h1>
  </Blocks.Root>
)
`}</code></pre>
    <h4>{`Output`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Blocks from 'blocks-ui/components'

export default () => (
  <Blocks.Root>
    <h1 ___uuid="123abc">Hello, world!</h1>
  </Blocks.Root>
)
`}</code></pre>
    <h3>{`Renderable representation`}</h3>
    <p>{`The render transform takes the internal representation and
then does the following:`}</p>
    <ul>
      <li parentName="ul">{`converts JSX to function calls (using the Blocks pragma)`}</li>
      <li parentName="ul">{`replaces `}<inlineCode parentName="li">{`Blocks.Root`}</inlineCode>{` with a special `}<inlineCode parentName="li">{`BLOCKS_Root`}</inlineCode>{` for drag and drop`}</li>
      <li parentName="ul">{`injects the implementation of `}<inlineCode parentName="li">{`BLOCKS_Root`}</inlineCode></li>
      <li parentName="ul">{`removes imports`}</li>
      <li parentName="ul">{`rewrites the default export to be a `}<inlineCode parentName="li">{`BLOCKS_Container`}</inlineCode>{` variable`}</li>
      <li parentName="ul">{`rewrites all named exports to be variable declarations`}</li>
      <li parentName="ul">{`wraps all blocks on the canvas in the draggable implementation`}</li>
    </ul>
    <h4>{`Input`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Blocks from 'blocks-ui/components'

export default () => (
  <Blocks.Root>
    <h1 ___uuid="123abc">Hello, world!</h1>
  </Blocks.Root>
)
`}</code></pre>
    <h4>{`Output`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`TODO
`}</code></pre>
    <h2>{`Rendering the canvas`}</h2>
    <p>{`After the transform for a renderable output, Blocks uses a
custom pragma, AST metadata, custom scope (including blocks
passed to the editor) and inline function evaluation
to render the canvas.`}</p>
    <h3>{`Pragma`}</h3>
    <p>{`Blocks uses its own custom pragma which is used when rendering
to the canvas. This is used for handling the UUID, element
selection, and styling based on selection/hover/focus state.`}</p>
    <p>{`It's a light wrapper around Theme UI's custom pragma. It's
rough implementation looks something like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export default elementSelectionHandler => (type, props, ...children) => {
  // Grab the current selected element from context
  const element = useElement() || {}

  props = props || {}
  const { ___uuid: id, sx = {} } = props
  delete props.___uuid

  const isCurrentElement = id && id === element.id

  return jsx(
    type,
    {
      ...props,
      sx: {
        ...sx,
        boxShadow: isCurrentElement
          ? 'inset 0px 0px 0px 2px #0079FF'
          : sx.boxShadow
      },
      onClick: e => {
        e.stopPropagation()
        if (id) {
          elementSelectionHandler(id)
        }
      }
    },
    ...children
  )
}
`}</code></pre>
    <h3>{`Renderer`}</h3>
    <p>{`The renderer itself receives the transformed source code
and the scope of the canvas and its Blocks. Then, it initializes
a function and evaluates it inline:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const fn = new Function(
  'React',
  ...Object.keys(scope),
  \`\${code};
  return React.createElement(BLOCKS_Container)\`
)

return fn(React, ...Object.values(scope))
`}</code></pre>
    <p>{`This is wrapped up in a component so the API is nicer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<InlineRender scope={scope} code={transformedCode} theme={theme} />
`}</code></pre>
    <h3>{`Querying element information`}</h3>
    <p>{`In addition to Babel transforms, Blocks needs to query information
from the AST in order to query information about a selected element.
Blocks uses information including:`}</p>
    <ul>
      <li parentName="ul">{`element name`}</li>
      <li parentName="ul">{`parent UUID`}</li>
      <li parentName="ul">{`child names and UUIDs`}</li>
      <li parentName="ul">{`text (if all children are text nodes)`}</li>
    </ul>
    <p>{`In order to achieve this, a Babel plugin is run to search for a
JSX element with a matching UUID and then pulling information from
the node in the AST.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  JSXOpeningElement: path => {
    const id = path.node.attributes.find(
      // uuidName is the matching uuid property and it's equal to ___uuid
      node => node && node.name && node.name.name === uuidName
    )

    if (!id || id.value.value !== elementId) {
      return
    }

    const children = path.container.children || []
    const hasElements = children.some(n => !t.isJSXText(n))

    const element = {
      id: elementId,
      name: getElementName(path.node),
      props: getElementProps(path.node.attributes),
      parentId: getParentId(path)
    }

    // ...
  }
}
`}</code></pre>
    <p>{`In addition to the current element, Blocks queries other metadata
from the AST including imports, exports, and the Blocks for the
canvas.`}</p>
    <p>{`Queries are wrapped up into a `}<inlineCode parentName="p">{`queries`}</inlineCode>{` module which can be used
like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`queries.getCurrentElement(code, elementId)
`}</code></pre>
    <h2>{`Returning new JSX`}</h2>
    <p>{`The new, modified JSX code is returned in an `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` event.
It takes the internal representation of the JSX and runs a
Babel plugin to remove the UUID and any other Blocks metadata.
This results in`}</p>
    <h4>{`Input`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Blocks from 'blocks-ui/components'

export default () => (
  <Blocks.Root>
    <h1
      ___uuid="123abc"
      sx={{
        color: 'tomato'
      }}
    >
      Hello, world!
    </h1>
  </Blocks.Root>
)
`}</code></pre>
    <h4>{`Output`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import Blocks from 'blocks-ui/components'

export default () => (
  <Blocks.Root>
    <h1 sx={{ color: 'tomato' }}>Hello, world!</h1>
  </Blocks.Root>
)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      